::selection {
  background: #89a4f6;
}

html {
  scroll-behavior: smooth;
}

.hover::hover {
  cursor: pointer;
}

.add-scribble-below {
  display: inline-block;
  padding-bottom: 10px;
  background-image: url('/scribble-below.svg');
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.slide-in-top {
  -webkit-animation: scale-in-ver-top 0.3s ease-in-out both;
  animation: scale-in-ver-top 0.3s ease-in-out both;
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s both;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: white;
}

.slick-dots li button:before {
  font-size: 14px;
  line-height: 50px;
  position: absolute;
  top: 10;
  left: 0;
  width: 0px;
  height: 0px;
  content: '•';
  text-align: center;
  opacity: 0.39;
  color: white;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
}

strong {
  color: #335eea;
  /* font-weight: 800; */
}

body {
  font-family: 'DM Sans', sans-serif;
  color: #111;
  font-size: 14px;
  line-height: 20px;
  /* font-weight: 700; */
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div {
  display: block;
}
.body-content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: 'DM Sans', sans-serif;
  color: #111;
  font-size: 14px;
  line-height: 20px;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}
.w-richtext:after,
.w-richtext:before {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-richtext:after {
  clear: both;
}
.w-richtext:after,
.w-richtext:before {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-container:after {
  clear: both;
}
.w-container:after,
.w-container:before {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-container:after,
.w-container:before {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.body-content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .slick-prev::before,
body .slick-next::before {
  display: none;
}

/*keyframe animations*/
.ticker {
  -webkit-animation: bannermove 110s linear infinite;
  -moz-animation: bannermove 110s linear infinite;
  -ms-animation: bannermove 110s linear infinite;
  -o-animation: bannermove 110s linear infinite;
  animation: bannermove 110s linear infinite;
}

@keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2125px;
  }
}

@-moz-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2125px;
  }
}

@-webkit-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2125px;
  }
}

@-ms-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2125px;
  }
}

@-o-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2125px;
  }
}
