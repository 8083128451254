@font-face {
font-family: '__neueMontreal_b67e26';
src: url(/_next/static/media/dc03e58dafb0f94e.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__neueMontreal_b67e26';
src: url(/_next/static/media/614210d7392d1f42.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__neueMontreal_b67e26';
src: url(/_next/static/media/b63e85fe250c7eb1.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__neueMontreal_Fallback_b67e26';src: local("Arial");ascent-override: 99.93%;descent-override: 25.24%;line-gap-override: 0.00%;size-adjust: 95.87%
}.__className_b67e26 {font-family: '__neueMontreal_b67e26', '__neueMontreal_Fallback_b67e26'
}

@font-face {
font-family: '__geomanist_d900f7';
src: url(/_next/static/media/67a3283610583938.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}@font-face {font-family: '__geomanist_Fallback_d900f7';src: local("Arial");ascent-override: 98.41%;descent-override: 30.16%;line-gap-override: 0.00%;size-adjust: 102.96%
}.__className_d900f7 {font-family: '__geomanist_d900f7', '__geomanist_Fallback_d900f7';font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_5cb4d6';src: local("Arial");ascent-override: 94.72%;descent-override: 29.60%;line-gap-override: 0.00%;size-adjust: 104.73%
}.__className_5cb4d6 {font-family: '__DM_Sans_5cb4d6', '__DM_Sans_Fallback_5cb4d6';font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_5cb4d6';src: local("Arial");ascent-override: 94.72%;descent-override: 29.60%;line-gap-override: 0.00%;size-adjust: 104.73%
}.__className_5cb4d6 {font-family: '__DM_Sans_5cb4d6', '__DM_Sans_Fallback_5cb4d6';font-style: normal
}

::selection {
  background: #89a4f6;
}

html {
  scroll-behavior: smooth;
}

.hover::hover {
  cursor: pointer;
}

.add-scribble-below {
  display: inline-block;
  padding-bottom: 10px;
  background-image: url('/scribble-below.svg');
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.slide-in-top {
  animation: scale-in-ver-top 0.3s ease-in-out both;
}
@keyframes scale-in-ver-top {
  0% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.tracking-in-expand {
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s both;
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: white;
}

.slick-dots li button:before {
  font-size: 14px;
  line-height: 50px;
  position: absolute;
  top: 10;
  left: 0;
  width: 0px;
  height: 0px;
  content: '•';
  text-align: center;
  opacity: 0.39;
  color: white;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
}

strong {
  color: #335eea;
  /* font-weight: 800; */
}

body {
  font-family: 'DM Sans', sans-serif;
  color: #111;
  font-size: 14px;
  line-height: 20px;
  /* font-weight: 700; */
}

* {
  box-sizing: border-box;
}
div {
  display: block;
}
.body-content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: 'DM Sans', sans-serif;
  color: #111;
  font-size: 14px;
  line-height: 20px;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}
.w-richtext:after,
.w-richtext:before {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-richtext:after {
  clear: both;
}
.w-richtext:after,
.w-richtext:before {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-container:after {
  clear: both;
}
.w-container:after,
.w-container:before {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-container:after,
.w-container:before {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.body-content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .slick-prev::before,
body .slick-next::before {
  display: none;
}

/*keyframe animations*/
.ticker {
  animation: bannermove 110s linear infinite;
}

@keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2125px;
  }
}

